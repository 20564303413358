import { StartWithUnit } from '@core/operators/start-with-unit';
import { inject, Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import { DataTransferService } from '@api/services/data-transfer.service';
import { LoadaDataRequest } from '@api/models/DataTransfer/Contract/loada-data-request';
import { startWithLoading } from '@core/operators/start-with-loading';
import { LoadDataResponse } from '@api/models/DataTransfer/Contract/load-data-response';


interface ILoadDataState {
    loading: Record<StartWithUnit, boolean>;
}

@Injectable()
export class LoadDataState extends RxState<ILoadDataState> {
    readonly #dataTransferService: DataTransferService = inject(DataTransferService);

    public readonly loading$: Observable<Record<StartWithUnit, boolean>> = this.select('loading');

    public loadData(body: LoadaDataRequest): Observable<LoadDataResponse[]> {
        return this.#dataTransferService.apiDataTransferTransferDataToDwhPost({ body }).pipe(
            startWithLoading(this, StartWithUnit.LoadData),
        );
    }

    constructor() {
        super();
        this.setDefaultState();
    }

    private setDefaultState(): void {
        this.set({
            loading: null,
        });
    }
}
