import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { isNil } from 'lodash-es';
import { RoutingQueryParamsName } from '@core/router/routing.params';
import { ReportsState } from '../state/reports.state';
import { ReportGetResponse } from '@api/models/Analytic/Contract/Reports/report-get-response';


@Injectable({
    providedIn: 'root',
})
export class ReportResolverService {
    readonly #reportsState: ReportsState = inject(ReportsState);

    public resolve(
        route: ActivatedRouteSnapshot,
    ): Observable<ReportGetResponse> | Observable<never> {
        const key: string = route.paramMap.get(RoutingQueryParamsName.Key);

        return isNil(key) ? null : this.#reportsState.detailDataReport(key).pipe(take(1));
    }
}
