import { StartWithUnit } from '@core/operators/start-with-unit';
import { inject, Injectable } from '@angular/core';
import { RxActionFactory, RxActions } from '@rx-angular/state/actions';
import { AnalyticApiService } from '@api/services/analytic-api.service';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { startWithLoading } from '@core/operators/start-with-loading';
import { DashboardMenuListResponse } from '@api/models/Analytic/Contract/Dashboard/dashboard-menu-list-response';
import { DashboardListResponse } from '@api/models/Analytic/Contract/Dashboard/dashboard-list-response';
import { ReportListResponse } from '@api/models/Analytic/Contract/Reports/report-list-response';
import { DashboardFolderListResponse } from '@api/models/Analytic/Contract/Dashboard/dashboard-folder-list-response';
import { DashboardFolderAddRequest } from '@api/models/Analytic/Contract/Dashboard/dashboard-folder-add-request';
import { DashboardFolderAddResponse } from '@api/models/Analytic/Contract/Dashboard/dashboard-folder-add-response';
import { DashboardFolderSetRequest } from '@api/models/Analytic/Contract/Dashboard/dashboard-folder-set-request';
import { DashboardFolderGetResponse } from '@api/models/Analytic/Contract/Dashboard/dashboard-folder-get-response';
import { DashboardAddRequest } from '@api/models/Analytic/Contract/Dashboard/dashboard-add-request';
import { DashboardAddResponse } from '@api/models/Analytic/Contract/Dashboard/dashboard-add-response';
import { DashboardCopyAddRequest } from '@api/models/Analytic/Contract/Dashboard/dashboard-copy-add-request';
import { DashboardCopyAddResponse } from '@api/models/Analytic/Contract/Dashboard/dashboard-copy-add-response';
import { DashboardSetRequest } from '@api/models/Analytic/Contract/Dashboard/dashboard-set-request';
import { DashboardGetResponse } from '@api/models/Analytic/Contract/Dashboard/dashboard-get-response';
import { DashboardInfoGetResponse } from '@api/models/Analytic/Contract/Dashboards/dashboard-info-get-response';
import { DashboardRoleListResponse } from '@api/models/Analytic/Contract/Dashboard/dashboard-role-list-response';
import { CustomRxState } from '@core/state/rx.state';
import { DashboardResSetRequest } from '@api/models/Analytic/Contract/Dashboards/dashboard-res-set-request';


interface IDashboardsState {
    loading: Record<StartWithUnit, boolean>;
    dashboardsMenuList: DashboardMenuListResponse[];
    dashboards: DashboardListResponse[];
    folders: DashboardFolderListResponse[];
    roles: DashboardRoleListResponse[];
}

interface DashboardsActions {
    requestDashboardsMenuList: DashboardMenuListResponse[];
    requestDashboards: DashboardListResponse[];
    requestFolders: DashboardFolderListResponse[];
    requestRoles: DashboardRoleListResponse[];
}

@Injectable()
export class DashboardsState extends CustomRxState<IDashboardsState> {

    readonly #analyticApiService: AnalyticApiService = inject(AnalyticApiService);
    readonly #actions: RxActions<DashboardsActions> = new RxActionFactory<DashboardsActions>().create();

    public readonly loading$: Observable<Record<StartWithUnit, boolean>> = this.select('loading');
    public readonly dashboards$: Observable<ReportListResponse[]> = this.select('dashboards');

    public readonly folders$: Observable<DashboardFolderListResponse[]> = this.select('folders').pipe(
        map((items: DashboardFolderListResponse[]) =>
            items.map((item: DashboardFolderListResponse) => {
                /** Modify response for tree factory */
                if (item.pFolderId === 0) {
                    return { ...item, pFolderId: null };
                }

                return item;
            }),
        ),
    );

    public readonly dashboardsMenuList$: Observable<DashboardMenuListResponse[]> = this.select('dashboardsMenuList').pipe(
        filter(Boolean),
        map((items: DashboardMenuListResponse[]) =>
            items.map((item: DashboardMenuListResponse) => {
                /** Modify response for tree factory */
                if (item.pFolderId === 0) {
                    return { ...item, pFolderId: null };
                }

                if (item.folderId === null) {
                    return { ...item, folderId: -1 };
                }

                return item;
            }),
        ),
    );

    public readonly roles$: Observable<DashboardRoleListResponse[]> = this.selectLazy('roles', this.requestRoles);

    constructor() {
        super();

        this.setDefaultState();
        this.connectSelectors();

        this.requestDashboardsMenuList();
        this.requestFolders();
        this.requestDashboards();
    }

    public requestDashboardsMenuList(): void {
        this.hold(
            this.#analyticApiService.apiAnalyticApiDashboardMenuListGet(),
            this.#actions.requestDashboardsMenuList,
        );
    }

    public requestDashboards(): void {
        this.hold(
            this.#analyticApiService.apiAnalyticApiDashboardListGet(),
            this.#actions.requestDashboards,
        );
    }

    public requestFolders(): void {
        this.hold(
            this.#analyticApiService.apiAnalyticApiDashboardFolderListGet(),
            this.#actions.requestFolders,
        );
    }

    public addDashboard(body: DashboardAddRequest): Observable<DashboardAddResponse> {
        return this.#analyticApiService.apiAnalyticApiDashboardAddPost({ body }).pipe(
            startWithLoading(this, StartWithUnit.AddDashboard),
            tap(() => this.requestDashboards()),
        );
    }

    public editDashboard(body: DashboardSetRequest): Observable<void> {
        return this.#analyticApiService.apiAnalyticApiDashboardSetPost({ body }).pipe(
            startWithLoading(this, StartWithUnit.EditDashboard),
            tap(() => this.requestDashboards()),
        );
    }

    public copyDashboard(body: DashboardCopyAddRequest): Observable<DashboardCopyAddResponse> {
        return this.#analyticApiService.apiAnalyticApiDashboardCopyAddPost({ body }).pipe(
            startWithLoading(this, StartWithUnit.CopyDashboard),
            tap(() => this.requestDashboards()),
        );
    }

    public deleteDashboard(key: string): Observable<void> {
        return this.#analyticApiService.apiAnalyticApiDashboardDeleteDelete({ key }).pipe(
            startWithLoading(this, StartWithUnit.DeleteDashboard),
            tap(() => this.requestDashboards()),
        );
    }

    public detailDataDashboard(key: string): Observable<DashboardGetResponse> {
        return this.#analyticApiService.apiAnalyticApiDashboardGetGet({ key });
    }

    public descriptionDashboard(key: string): Observable<DashboardInfoGetResponse> {
        return this.#analyticApiService.apiAnalyticApiDashboardInfoGetGet({ key });
    }

    public createFolder(body: DashboardFolderAddRequest): Observable<DashboardFolderAddResponse> {
        return this.#analyticApiService.apiAnalyticApiDashboardFolderAddPost({ body }).pipe(
            startWithLoading(this, StartWithUnit.CreateFolder),
            tap(() => this.requestFolders()),
        );
    }

    public editFolder(body: DashboardFolderSetRequest): Observable<void> {
        return this.#analyticApiService.apiAnalyticApiDashboardFolderSetPost({ body }).pipe(
            startWithLoading(this, StartWithUnit.EditFolder),
            tap(() => this.requestFolders()),
        );
    }

    public deleteFolder(folderId: number): Observable<void> {
        return this.#analyticApiService.apiAnalyticApiDashboardFolderDeleteDelete({ folderId }).pipe(
            startWithLoading(this, StartWithUnit.DeleteFolder),
            tap(() => this.requestFolders()),
        );
    }

    public detailDataFolder(folderId: number): Observable<DashboardFolderGetResponse> {
        return this.#analyticApiService.apiAnalyticApiDashboardFolderGetGet({ folderId });
    }

    public saveResources(body: DashboardResSetRequest): Observable<void> {
        return this.#analyticApiService.apiAnalyticApiDashboardResSetPost({ body }).pipe(
            startWithLoading(this, StartWithUnit.SaveDashboardResources),
        );
    }

    private requestRoles(): Observable<DashboardRoleListResponse[]> {
        return this.#analyticApiService.apiAnalyticApiDashboardRoleListGet();
    }

    private setDefaultState(): void {
        this.set({
            loading: null,
            dashboardsMenuList: null,
            dashboards: null,
            roles: null,
        });
    }

    private connectSelectors(): void {
        this.connect('dashboardsMenuList', this.#actions.requestDashboardsMenuList$);
        this.connect('dashboards', this.#actions.requestDashboards$);
        this.connect('folders', this.#actions.requestFolders$);
        this.connect('roles', this.#actions.requestRoles$);
    }
}
