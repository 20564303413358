import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CommonAppModule } from '@ui/common-app/common-app.module';
import { CommonAppComponent } from '@ui/common-app/common-app.component';
import { ThirdLevelMenuFactory } from '@libs/menu/third-level-menu.service';
import { thirdLevelMenu } from './sub.menu';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { ReportsState } from './shared/state/reports.state';
import { LoadDataState } from './shared/state/load-data.state';
import { DashboardsState } from './shared/state/dashboards.state';


export const loadSubMenu: (thirdLevelMenuFactory: ThirdLevelMenuFactory) => () => Promise<boolean> =
    (thirdLevelMenuFactory: ThirdLevelMenuFactory) => () =>
        thirdLevelMenuFactory.loadMenu(thirdLevelMenu);

@NgModule({
    imports: [
        AppRoutingModule,
        CommonAppModule,
        EditorModule,
    ],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
        {
            provide: APP_INITIALIZER,
            useFactory: loadSubMenu,
            deps: [ThirdLevelMenuFactory],
            multi: true,
        },
        ReportsState,
        LoadDataState,
        DashboardsState,
    ],
    bootstrap: [CommonAppComponent],
})
export class AppModule {
}
