import { MenuItemsModel } from '@libs/menu/menu.model';
import { RouteEntity } from '@core/router/route.entity';
import { PrivilegesModules } from '@core/privileges/privileges-modules';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { PrivilegesUsers } from '@core/privileges/privileges-users';


export const submenu: MenuItemsModel[] = [
    {
        mdId: 1,
        mdName: marker('reports.head'),
        url: RouteEntity.Root + RouteEntity.Reports,
        objName: [PrivilegesModules.AnmanReports],
    },
    {
        mdId: 2,
        mdName: marker('reports-editor.head'),
        url: RouteEntity.Root + RouteEntity.ReportsEditor,
        objName: [PrivilegesModules.AnmanReports],
        needPrivilegeForView: [{ [PrivilegesModules.AnmanReports]: [PrivilegesUsers.Edit] }],
    },
    {
        mdId: 3,
        mdName: marker('dashboards.head'),
        url: RouteEntity.Root + RouteEntity.Dashboards,
        objName: [PrivilegesModules.AnmanDashboard],
    },
    {
        mdId: 4,
        mdName: marker('dashboards-editor.head'),
        url: RouteEntity.Root + RouteEntity.DashboardsEditor,
        objName: [PrivilegesModules.AnmanDashboard],
        needPrivilegeForView: [{ [PrivilegesModules.AnmanDashboard]: [PrivilegesUsers.Edit] }],
    },
    {
        mdId: 5,
        mdName: marker('load-data.head'),
        url: RouteEntity.Root + RouteEntity.LoadData,
        objName: [PrivilegesModules.ReportsLoad],
    },
];

export const thirdLevelMenu: Record<string, MenuItemsModel[]> = {
    dashboard: [
        {
            mdId: 1,
            mdName: marker('global.labels.info'),
            urlTemplate: RouteEntity.Root + RouteEntity.DashboardsEditor + '/#key#/' + RouteEntity.Info,
        },
        {
            mdId: 2,
            mdName: marker('dashboards-editor.translate'),
            urlTemplate: RouteEntity.Root + RouteEntity.DashboardsEditor + '/#key#/' + RouteEntity.Resources,
        },
    ],
};
