import { StartWithUnit } from '@core/operators/start-with-unit';
import { inject, Injectable } from '@angular/core';
import { RxActionFactory, RxActions } from '@rx-angular/state/actions';
import { AnalyticApiService } from '@api/services/analytic-api.service';
import { Observable } from 'rxjs';
import { ReportMenuListResponse } from '@api/models/Analytic/Contract/Reports/report-menu-list-response';
import { filter, map, tap } from 'rxjs/operators';
import { ReportListResponse } from '@api/models/Analytic/Contract/Reports/report-list-response';
import { ReportAddRequest } from '@api/models/Analytic/Contract/Reports/report-add-request';
import { ReportAddResponse } from '@api/models/Analytic/Contract/Reports/report-add-response';
import { startWithLoading } from '@core/operators/start-with-loading';
import { ReportFolderListResponse } from '@api/models/Analytic/Contract/Reports/report-folder-list-response';
import { ReportFolderAddRequest } from '@api/models/Analytic/Contract/Reports/report-folder-add-request';
import { ReportFolderAddResponse } from '@api/models/Analytic/Contract/Reports/report-folder-add-response';
import { ReportCopyAddRequest } from '@api/models/Analytic/Contract/Reports/report-copy-add-request';
import { ReportCopyAddResponse } from '@api/models/Analytic/Contract/Reports/report-copy-add-response';
import { ReportFolderSetRequest } from '@api/models/Analytic/Contract/Reports/report-folder-set-request';
import { ReportFolderGetResponse } from '@api/models/Analytic/Contract/Reports/report-folder-get-response';
import { ReportGetResponse } from '@api/models/Analytic/Contract/Reports/report-get-response';
import { ReportInfoGetResponse } from '@api/models/Analytic/Contract/Reports/report-info-get-response';
import { ReportSetRequest } from '@api/models/Analytic/Contract/Reports/report-set-request';
import { ReportRoleListResponse } from '@api/models/Analytic/Contract/Reports/report-role-list-response';
import { CustomRxState } from '@core/state/rx.state';
import { ListIdtypeResponse } from '@api/models/AntifrodApi/Contract/list-idtype-response';


interface IReportsState {
    loading: Record<StartWithUnit, boolean>;
    reportsMenuList: ReportMenuListResponse[];
    reports: ReportListResponse[];
    folders: ReportFolderListResponse[];
    roles: ReportRoleListResponse[];
}

interface ReportsActions {
    requestReportsMenuList: ReportMenuListResponse[];
    requestReports: ReportListResponse[];
    requestFolders: ReportFolderListResponse[];
    requestRoles: ReportRoleListResponse[];
}

@Injectable()
export class ReportsState extends CustomRxState<IReportsState> {

    readonly #analyticApiService: AnalyticApiService = inject(AnalyticApiService);
    readonly #actions: RxActions<ReportsActions> = new RxActionFactory<ReportsActions>().create();

    public readonly loading$: Observable<Record<StartWithUnit, boolean>> = this.select('loading');
    public readonly reports$: Observable<ReportListResponse[]> = this.select('reports');
    public readonly folders$: Observable<ReportFolderListResponse[]> = this.select('folders').pipe(
        map((items: ReportFolderListResponse[]) =>
            items.map((item: ReportFolderListResponse) => {
                /** Modify response for tree factory */
                if (item.pFolderId === 0) {
                    return { ...item, pFolderId: null };
                }

                return item;
            }),
        ),
    );

    public readonly reportsMenuList$: Observable<ReportMenuListResponse[]> = this.select('reportsMenuList').pipe(
        filter(Boolean),
        map((items: ReportMenuListResponse[]) =>
            items.map((item: ReportMenuListResponse) => {
                /** Modify response for tree factory */
                if (item.pFolderId === 0) {
                    return { ...item, pFolderId: null };
                }

                if (item.folderId === null) {
                    return { ...item, folderId: -1 };
                }

                return item;
            }),
        ),
    );

    public readonly roles$: Observable<ReportRoleListResponse[]> = this.selectLazy('roles', this.requestRoles);

    constructor() {
        super();

        this.setDefaultState();
        this.connectSelectors();

        this.requestReportsMenuList();
        this.requestFolders();
        this.requestReports();
    }

    public requestReportsMenuList(): void {
        this.hold(
            this.#analyticApiService.apiAnalyticApiReportMenuListGet(),
            this.#actions.requestReportsMenuList,
        );
    }

    public requestReports(): void {
        this.hold(
            this.#analyticApiService.apiAnalyticApiReportListGet(),
            this.#actions.requestReports,
        );
    }

    public requestFolders(): void {
        this.hold(
            this.#analyticApiService.apiAnalyticApiReportFolderListGet(),
            this.#actions.requestFolders,
        );
    }

    public addReport(body: ReportAddRequest): Observable<ReportAddResponse> {
        return this.#analyticApiService.apiAnalyticApiReportAddPost({ body }).pipe(
            startWithLoading(this, StartWithUnit.AddReport),
            tap(() => this.requestReports()),
        );
    }

    public copyReport(body: ReportCopyAddRequest): Observable<ReportCopyAddResponse> {
        return this.#analyticApiService.apiAnalyticApiReportCopyAddPost({ body }).pipe(
            startWithLoading(this, StartWithUnit.CopyReport),
            tap(() => this.requestReports()),
        );
    }

    public deleteReport(key: string): Observable<void> {
        return this.#analyticApiService.apiAnalyticApiReportDeleteDelete({ key }).pipe(
            startWithLoading(this, StartWithUnit.DeleteReport),
            tap(() => this.requestReports()),
        );
    }

    public editReport(body: ReportSetRequest): Observable<void> {
        return this.#analyticApiService.apiAnalyticApiReportSetPost({ body }).pipe(
            startWithLoading(this, StartWithUnit.EditReport),
            tap(() => this.requestReports()),
        );
    }

    public detailDataReport(key: string): Observable<ReportGetResponse> {
        return this.#analyticApiService.apiAnalyticApiReportGetGet({ key });
    }

    public descriptionReport(key: string): Observable<ReportInfoGetResponse> {
        return this.#analyticApiService.apiAnalyticApiReportInfoGetGet({ key });
    }

    public createFolder(body: ReportFolderAddRequest): Observable<ReportFolderAddResponse> {
        return this.#analyticApiService.apiAnalyticApiReportFolderAddPost({ body }).pipe(
            startWithLoading(this, StartWithUnit.CreateFolder),
            tap(() => this.requestFolders()),
        );
    }

    public editFolder(body: ReportFolderSetRequest): Observable<void> {
        return this.#analyticApiService.apiAnalyticApiReportFolderSetPost({ body }).pipe(
            startWithLoading(this, StartWithUnit.EditFolder),
            tap(() => this.requestFolders()),
        );
    }

    public deleteFolder(folderId: number): Observable<void> {
        return this.#analyticApiService.apiAnalyticApiReportFolderDeleteDelete({ folderId }).pipe(
            startWithLoading(this, StartWithUnit.DeleteFolder),
            tap(() => this.requestFolders()),
        );
    }

    public detailDataFolder(folderId: number): Observable<ReportFolderGetResponse> {
        return this.#analyticApiService.apiAnalyticApiReportFolderGetGet({ folderId });
    }

    private requestRoles(): Observable<ListIdtypeResponse[]> {
        return this.#analyticApiService.apiAnalyticApiReportRoleListGet();
    }

    private setDefaultState(): void {
        this.set({
            loading: null,
            reportsMenuList: null,
            reports: null,
            roles: null,
        });
    }

    private connectSelectors(): void {
        this.connect('reportsMenuList', this.#actions.requestReportsMenuList$);
        this.connect('reports', this.#actions.requestReports$);
        this.connect('folders', this.#actions.requestFolders$);
        this.connect('roles', this.#actions.requestRoles$);
    }
}
