import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavbarComponent } from '@ui/navbar/navbar.component';
import { AuthGuard } from '@core/auth/auth.guard';
import { submenu } from './sub.menu';
import { ReportResolverService } from './shared/resolvers/report-resolver.service';
import { RouteEntity } from '@core/router/route.entity';
import { MenuItemsModel } from '@libs/menu/menu.model';
import { commonErrorRoutes, commonModuleRoutes } from '@core/router/routing.common';


const dataPrivilege: Record<'moduleMenu', MenuItemsModel[]> = {
    moduleMenu: submenu,
};

const routes: Routes = [
    ...commonModuleRoutes,
    {
        path: RouteEntity.Empty,
        component: NavbarComponent,
        outlet: 'navbar',
        data: {
            subMenuItems: submenu,
        },
    },
    {
        path: RouteEntity.Empty,
        redirectTo: RouteEntity.Reports,
        pathMatch: 'full',
    },
    {
        path: RouteEntity.ReportsEditor + '/' + RouteEntity.Add,
        loadChildren: () =>
            import('./features/report-add/report-add.module').then(
                (m) => m.ReportAddModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.ReportsEditor + '/:key',
        loadChildren: () =>
            import('./features/report/report.module').then(
                (m) => m.ReportModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
        resolve: {
            report: ReportResolverService,
        },
    },
    {
        path: RouteEntity.ReportsEditor,
        loadChildren: () =>
            import('./features/reports-editor/reports-editor.module').then(
                (m) => m.ReportsEditorModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Reports + '/' + RouteEntity.View + '/:key',
        loadChildren: () =>
            import('./features/report-view/report-view.module').then(
                (m) => m.ReportViewModule,
            ),
        canLoad: [AuthGuard],
    },
    {
        path: RouteEntity.Reports,
        loadChildren: () =>
            import('./features/reports/reports.module').then(
                (m) => m.ReportsModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Dashboards,
        loadChildren: () =>
            import('./features/dashboards/dashboards.module').then(
                (m) => m.DashboardsModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.DashboardsEditor + '/' + RouteEntity.Add,
        loadChildren: () =>
            import('./features/dashboard-add/dashboard-add.module').then(
                (m) => m.DashboardAddModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.DashboardsEditor + '/:key',
        loadChildren: () =>
            import('./features/dashboard/dashboard.module').then(
                (m) => m.DashboardModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.DashboardsEditor,
        loadChildren: () =>
            import(
                './features/dashboards-editor/dashboards-editor.module'
                ).then((m) => m.DashboardsEditorModule),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    {
        path: RouteEntity.Reports + '/' + RouteEntity.View + '/:key',
        loadChildren: () =>
            import('./features/report-view/report-view.module').then(
                (m) => m.ReportViewModule,
            ),
        canLoad: [AuthGuard],
    },
    {
        path: RouteEntity.Dashboards + '/' + RouteEntity.View + '/:key',
        loadChildren: () =>
            import('./features/dashboard-view/dashboard-view.module').then(
                (m) => m.DashboardViewModule,
            ),
        canLoad: [AuthGuard],
    },
    {
        path: RouteEntity.LoadData,
        loadChildren: () =>
            import('./features/load-data/load-data.module').then(
                (m) => m.LoadDataModule,
            ),
        canLoad: [AuthGuard],
        data: dataPrivilege,
    },
    ...commonErrorRoutes,
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
